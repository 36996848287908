import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HeaderBar from "../components/header/HeaderBar";
import HeaderDesktop from "../components/header/HeaderDesktop";
import Packets from "../components/Packets";
import SellingPoint from "../components/SellingPoints";
import WhatsappIcon from "../components/WhatsappIcon";
import SellingPoints2 from "../components/SellingPoints2";
import RequestLessonBanner from "../components/RequestLessonBanner";
import Reviews from "../components/Reviews";
import SiteMap from "../components/SiteMap";
import NicanTitle from "../components/NickanTitle";
import ContactInfoBanner from "../components/ContactInfoBanner";
import RequestLessonBannerSmall from "../components/RequestLessonBannerSmall";
import "../App.css";

export default function Home({ location = "" }) {
  const theme = useTheme();
  const mdscreen = useMediaQuery(theme.breakpoints.down("md"));
  const navItems = ["tarieven", "contact", "lng", "proefles"];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={styles.container}>
      <WhatsappIcon />
      <HeaderBar navItems={navItems} />
      <Box component="main">
        <div style={styles.container}>
          <div
            className={mdscreen ? "backimgMobile" : "backimg"}
            style={{ height: "100vh" }}
          >
            <Container maxWidth="md">
              {mdscreen && <Toolbar />}
              <HeaderDesktop items={navItems} />
              <div style={styles.autorijschool}>
                <NicanTitle location={location} />
                <SellingPoint />
              </div>
            </Container>
          </div>
          <Container maxWidth="sm">
            <RequestLessonBannerSmall />
          </Container>
          <Container maxWidth="lg">
            <Packets />
          </Container>
          <ContactInfoBanner />
          <Container maxWidth="md">
            <SellingPoints2 />
          </Container>
          <RequestLessonBanner />
          <Reviews />
          <SiteMap />
        </div>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  autorijschool: {
    marginTop: "60px",
    marginBottom: "10vh",
    textAlign: "center",
    color: "white",
  },
};
