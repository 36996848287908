import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HeaderBar from "../components/header/HeaderBar";
import HeaderDesktop from "../components/header/HeaderDesktop";
import RequestLessonBanner from "../components/RequestLessonBanner";
import Reviews from "../components/Reviews";
import SiteMap from "../components/SiteMap";
import WhatsappIcon from "../components/WhatsappIcon";
import Packets from "../components/Packets";
import NicanTitle from "../components/NickanTitle";
import { useTranslation } from "react-i18next";
import "../App.css";

export default function Prices() {
  const theme = useTheme();
  const mdscreen = useMediaQuery(theme.breakpoints.down("md"));
  const [t] = useTranslation();
  const navItems = ["home", "contact", "lng", "proefles"];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={styles.container}>
      <WhatsappIcon />
      <HeaderBar navItems={navItems} />
      <Box component="main">
        <div style={styles.container}>
          <div className="backimg" style={{ height: "40vh" }}>
            <Container maxWidth="md">
              {mdscreen && <Toolbar />}
              <HeaderDesktop items={navItems} />
              <div style={styles.autorijschool}>
                <NicanTitle />
              </div>
            </Container>
          </div>
          <Container maxWidth="lg">
            <Typography style={styles.title} variant="h4">
              {t("rijles pakkets")}
            </Typography>
            <Packets />
          </Container>
          <RequestLessonBanner />
          <Reviews />
          <SiteMap />
        </div>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  autorijschool: {
    marginTop: "5vh",
    marginBottom: "10vh",
    textAlign: "center",
    color: "white",
  },
  nickan: {
    marginTop: "10px",
    marginBottom: "5vh",
    fontWeight: "bold",
  },
  title: {
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "60px",
  },
};
