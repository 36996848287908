import React from "react";
import Button from "@mui/material/Button";

export default function LocatiesButton(props) {
  const { style, ...otherProps } = props;
  return (
    <Button variant="text" style={{ color: "black", ...style }} {...otherProps}>
      Locaties
    </Button>
  );
}
