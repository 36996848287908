import React from "react";
import { Link, Button } from "@mui/material";
import { Icon } from "@iconify/react";

export default function SnapchatLink({ style }) {
  return (
    <Link href="https://www.snapchat.com/add/rijschoolnickan" target="_blank">
      <Button
        style={style}
        variant="text"
        startIcon={<Icon icon="mdi:snapchat" />}
      >
        Snapchat
      </Button>
    </Link>
  );
}
