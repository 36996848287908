import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HomeButton(props) {
  const { style, ...otherProps } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  return (
    <Button
      variant="text"
      style={{ color: "black", ...style }}
      {...otherProps}
      onClick={() => navigate("/")}
    >
      {t("home")}
    </Button>
  );
}
