import React from "react";
import TarievenButton from "./buttons/TarievenButton";
import LocatiesButton from "./buttons/LocatiesButton";
import ContactButton from "./buttons/ContactButton";
import ProeflesButton from "./buttons/ProeflesButton";
import HomeButton from "./buttons/HomeButton";
import LangButton from "./buttons/LangButton";

export default function HeaderItems(props) {
  const headerItems = {
    tarieven: <TarievenButton {...props} />,
    locaties: <LocatiesButton {...props} />,
    contact: <ContactButton {...props} />,
    proefles: <ProeflesButton {...props} />,
    home: <HomeButton {...props} />,
    lng: <LangButton {...props} />,
  };

  return headerItems[props.item];
}
