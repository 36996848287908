import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SECONDARYCOLOR } from "../../../Constants";

export default function ProeflesButton(props) {
  const { style, ...otherProps } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <Button
      variant="contained"
      onClick={() => navigate("/proefles")}
      style={{
        background: SECONDARYCOLOR,
        fontSize: "0.9em",
        color: "white",
        fontWeight: "bold",
        borderRadius: "6px",
        alignSelf: "center",
        maxHeight: "50px",
        ...style,
      }}
      {...otherProps}
    >
      {t("proefles aanvragen")}
    </Button>
  );
}
