import React from "react";
import Container from "@mui/material/Container";
import { Typography, Button } from "@mui/material";
import { PRIMARYCOLOR, SECONDARYCOLOR } from "../Constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RequestLessonBanner() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <div style={styles.root}>
      <Container maxWidth="md">
        <div style={styles.container}>
          <Typography style={styles.title}>
            {t("haal direct rijbewijs")}
          </Typography>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => navigate("/proefles")}
              variant="contained"
              size="large"
              style={styles.button}
            >
              {t("proefles aanvragen")}!
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

const styles = {
  root: {
    background: PRIMARYCOLOR,
    marginTop: "10px",
    paddingBottom: "45px",
    paddingTop: "60px",
    marginBottom: "50px",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    minWidth: "300px",
    color: "white",
    fontWeight: "bold",
    fontSize: "1.5em",
    flexBasis: "40%",
    marginBottom: "20px",
  },
  buttonContainer: {
    flexGrow: 1,
    textAlign: "center",
    marginBottom: "15px",
  },
  button: {
    textAlign: "center",
    borderRadius: "25px",
    width: "330px",
    minWidth: "300px",
    height: "50px",
    color: "white",
    background: SECONDARYCOLOR,
    fontWeight: "bold",
    flexBasis: "40%",
    fontSize: "1.2em",
  },
};
