import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PRIMARYCOLOR, SECONDARYCOLOR } from "../Constants";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import "../App.css";

const backimgColor = PRIMARYCOLOR.split(")")[0] + "," + 0.8 + ")";

function Feat({ children, style }) {
  const styles = {
    feat: {
      color: "green",
      display: "flex",
      flexDirection: "row",
      marginLeft: "3.3vh",
    },
    checkIcon: {
      marginRight: "10px",
      marginTop: "2px",
    },
    featText: {
      fontStyle: "italic",
      color: "black",
      fontSize: "1.3em",
      textAlign: "left",
      marginRight: "10px",
      marginBottom: "10px",
      ...style,
    },
  };

  return (
    <div style={styles.feat}>
      <TaskAltIcon style={styles.checkIcon} />
      <Typography style={styles.featText} variant="h6">
        {children}
      </Typography>
    </div>
  );
}

export default function Packets() {
  const theme = useTheme();
  const [t] = useTranslation();
  const smscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={styles.root}>
      <div
        style={{
          ...styles.mainPricesContainer,
          flexWrap: smscreen ? "wrap" : "nowrap",
        }}
      >
        <Paper
          className={!smscreen && "paketContainer"}
          style={{
            ...styles.cardContainer,
            flexBasis: smscreen ? "100%" : 1,
          }}
          elevation={20}
        >
          <Typography style={styles.title}>{t("gevorderd")}</Typography>
          <Typography style={styles.price}>€ 390 x2</Typography>
          <div style={styles.featContainer}>
            <Feat>{t("10 rijlessen")}</Feat>
            <Feat>{t("allrisk")}</Feat>
          </div>
        </Paper>
        <Paper
          className={!smscreen && "paketContainer"}
          style={{
            ...styles.cardContainer,
            ...styles.featCard,
            marginTop: smscreen ? 0 : "-5vh",
            flexBasis: smscreen ? "100%" : 1,
            position: "relative",
          }}
          elevation={20}
        >
          <div className="ribbon-top">{t("tijdelijke actie")}</div>
          <Typography style={{ ...styles.title, color: "white" }}>
            {t("beginners")}
          </Typography>
          <Typography
            variant="h5"
            style={{ textDecoration: "line-through", color: "white" }}
          >
            € 1400,00
          </Typography>
          <Typography style={{ ...styles.price, color: "white" }}>
            € 645 x2
          </Typography>
          <div style={styles.featContainer}>
            <Feat style={styles.featCardText}>{t("26 rijlessen")}</Feat>
            <Feat style={styles.featCardText}>{t("gratis proefles")}</Feat>
            <Feat style={styles.featCardText}>{t("gratis herexamen")}</Feat>
            <Feat style={styles.featCardText}>{t("gespreid betalen")}</Feat>
            <Feat style={styles.featCardText}>{t("allrisk")}</Feat>
          </div>
        </Paper>
        <Paper
          className={!smscreen && "paketContainer"}
          style={{
            ...styles.cardContainer,
            flexBasis: smscreen ? "100%" : 1,
          }}
          elevation={20}
        >
          <Typography style={styles.title}>{t("complex")}</Typography>
          <Typography style={styles.price}>€ 460 x2</Typography>
          <div style={styles.featContainer}>
            <Feat>{t("15 rijlessen")}</Feat>
            <Feat>{t("gratis proefles")}</Feat>
            <Feat>{t("gratis herexamen")}</Feat>
            <Feat>{t("gespreid betalen")}</Feat>
            <Feat>{t("allrisk")}</Feat>
          </div>
        </Paper>
      </div>
      <Container maxWidth="md">
        <div style={styles.secondaryPricesContainer}>
          <Paper style={styles.secondaryPrices} elevation={5}>
            <Typography style={styles.secondaryPricesTitle}>
              {t("losse les")}
            </Typography>
            <Typography style={styles.secondaryPricesSubtitle}>
              {t("50 min")}
            </Typography>
            <Divider variant="middle" />
            <Typography style={styles.secondaryPricesAmount}>
              € 49,99
            </Typography>
          </Paper>
          <Paper style={styles.secondaryPrices} elevation={5}>
            <Typography style={styles.secondaryPricesTitle}>
              {t("praktijkexamen cbr")}
            </Typography>
            <Typography style={styles.secondaryPricesSubtitle}>
              {t("autohuur")}
            </Typography>
            <Divider variant="middle" />
            <Typography style={styles.secondaryPricesAmount}>
              € 299,99
            </Typography>
          </Paper>
          <Paper style={styles.secondaryPrices} elevation={5}>
            <Typography style={styles.secondaryPricesTitle}>
              {t("tussentijdse toets")}
            </Typography>
            <Typography style={styles.secondaryPricesSubtitle}>
              {t("autohuur")}
            </Typography>
            <Divider variant="middle" />
            <Typography style={styles.secondaryPricesAmount}>
              € 289,99
            </Typography>
          </Paper>
          <Paper style={styles.secondaryPrices} elevation={5}>
            <Typography style={styles.secondaryPricesTitle}>
              {t("faalangst examen")}
            </Typography>
            <Typography style={styles.secondaryPricesSubtitle}>
              {t("autohuur")}
            </Typography>
            <Divider variant="middle" />
            <Typography style={styles.secondaryPricesAmount}>
              € 399,99
            </Typography>
          </Paper>
        </div>
      </Container>
    </div>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mainPricesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "stretch",
    width: "100%",
    padding: "30px",
    gap: "15px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "340px",
    maxWidth: "450px",
    paddingBottom: "40px",
    height: "450px",
    transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  featCard: {
    backgroundImage: `linear-gradient(90deg,#1d1d1b, ${backimgColor})`,
    backgroundColor: "#1d1d1b",
    borderRadius: "8px",
    boxShadow: `5px 5px 25px ${SECONDARYCOLOR}`,
    color: "white",
  },
  featCardText: {
    color: "white",
  },
  title: {
    fontSize: "2.2em",
    fontWeight: "bold",
    marginTop: "20px",
    color: SECONDARYCOLOR,
  },
  price: {
    fontSize: "1.9em",
    fontWeight: "bold",
    color: SECONDARYCOLOR,
  },
  featContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4vh",
  },
  secondaryPricesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    height: "100%",
    gap: "10px",
    paddingBottom: "60px",
    marginTop: "50px",
  },
  secondaryPrices: {
    textAlign: "center",
    flexBasis: "40%",
    height: "100%",
    paddingBottom: "3vh",
    minWidth: "300px",
    flexGrow: 1,
    flex: 1,
  },
  secondaryPricesTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
    marginTop: "20px",
  },
  secondaryPricesAmount: {
    fontSize: "1.5em",
    fontWeight: "bold",
    marginTop: "20px",
    color: SECONDARYCOLOR,
  },
  secondaryPricesSubtitle: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "grey",
    marginBottom: "20px",
  },
};
