import React from "react";
import { Link, Typography, Container } from "@mui/material";
import PhoneLink from "./ContactButtons/PhoneLink";
import EmailLink from "./ContactButtons/EmailLink";
import WhatsAppLink from "./ContactButtons/WhatsAppLink";
import InstagramLink from "./ContactButtons/InstagramLink";
import SnapchatLink from "./ContactButtons/SnapchatLink";
import { PRIMARYCOLOR } from "../Constants";
import { useTranslation } from "react-i18next";

export default function SiteMap() {
  const [t] = useTranslation();

  return (
    <div style={styles.root}>
      <Container maxWidth="lg">
        <div style={styles.container}>
          <div style={styles.section}>
            <Typography style={styles.title}>{t("algemeen")} </Typography>
            <Link style={styles.link} href="/">
              {t("home")}
            </Link>
            <Link style={styles.link} href="/tarieven">
              {t("tarieven")}
            </Link>
            <Link style={styles.link} href="/proefles">
              {t("proefles aanvragen")}
            </Link>
            <Link style={styles.link} href="/contact">
              {t("contact")}
            </Link>
          </div>
          <div style={styles.section}>
            <Typography style={styles.title}>{t("waar rijden")}</Typography>
            <div style={styles.locations}>
              <div style={styles.locationSet}>
                <Link style={styles.link} href="/delft">
                  {t("Delft")}
                </Link>
                <Link style={styles.link} href="/denhaag">
                  {t("Den Haag")}
                </Link>
                <Link style={styles.link} href="/rotterdam-noord">
                  {t("Rotterdam Noord")}
                </Link>
              </div>
              <div style={styles.locationSet}>
                <Link style={styles.link} href="/schiedam">
                  {t("Schiedam")}
                </Link>
                <Link style={styles.link} href="/vlaardingen">
                  {t("Vlaardingen")}
                </Link>
                <Link style={styles.link} href="/zoetermeer">
                  {t("Zoetermeer")}
                </Link>
              </div>
            </div>
          </div>
          <div style={styles.section}>
            <Typography style={styles.title}> {t("contact")} </Typography>
            <PhoneLink style={styles.link} />
            <WhatsAppLink style={styles.link} />
            <EmailLink style={styles.link} />
            <InstagramLink style={styles.link} />
            <SnapchatLink style={styles.link} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const styles = {
  root: {
    background: PRIMARYCOLOR,
    paddingBottom: "50px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "100%",
    gap: "50px",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    marginTop: "50px",
    minWidth: "300px",
    justifyContent: "left",
    flex: 1,
    flexGrow: 1,
  },
  title: {
    fontStyle: "bold",
    fontSize: "1.5em",
    marginBottom: "50px",
  },
  link: {
    color: "white",
    marginTop: "20px",
    fontSize: "1em",
  },
  locations: {
    display: "flex",
    flexDirection: "row",
    gap: "50px",
  },
  locationSet: {
    display: "flex",
    flexDirection: "column",
  },
};
