import React from "react";
import { Paper, Typography, Container } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { PRIMARYCOLOR, SECONDARYCOLOR } from "../Constants";
import { useTranslation } from "react-i18next";

export default function Reviews() {
  const [t] = useTranslation();

  return (
    <Container maxWidth="lg">
      <div style={styles.container}>
        <Typography style={styles.reviewText}>{t("reviews")}</Typography>
        <Typography style={styles.title}>
          {t("wat leerlingen zeggen")}
        </Typography>
        <div style={styles.reviewsContainer}>
          <Paper elevation={10} style={styles.reviews}>
            <Typography variant="title" style={styles.reviewTitle}>
              Nigel
            </Typography>
            <Typography style={styles.reviewDesc}>
              <FormatQuoteIcon style={styles.quote} /> {t("review1")}
            </Typography>
          </Paper>
          <Paper elevation={10} style={styles.reviews}>
            <Typography variant="title" style={styles.reviewTitle}>
              Mostafa
            </Typography>
            <Typography style={styles.reviewDesc}>
              <FormatQuoteIcon style={styles.quote} /> {t("review2")}
            </Typography>
          </Paper>
          <Paper elevation={10} style={styles.reviews}>
            <Typography variant="title" style={styles.reviewTitle}>
              Saar
            </Typography>
            <Typography style={styles.reviewDesc}>
              <FormatQuoteIcon style={styles.quote} /> {t("review3")}
            </Typography>
          </Paper>
        </div>
      </div>
    </Container>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  title: {
    color: "black",
    fontStyle: "bold",
    fontSize: "2em",
    textAlign: "center",
  },
  reviewsContainer: {
    marginTop: "50px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
  reviews: {
    height: "250px",
    minWidth: "300px",
    padding: "15px",
    flexBasis: "30%",
    flexGrow: 1,
  },
  reviewTitle: {
    fontSize: "1.2em",
    fontStyle: "italic",
    padding: "10px",
  },
  quote: {
    color: SECONDARYCOLOR,
  },
  reviewDesc: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: "15px",
  },
  reviewText: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: PRIMARYCOLOR,
    marginBottom: "10px",
  },
};
