import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import HeaderItems from "./HeaderItems";
import { useTheme } from "@mui/material/styles";
import { LOGOWIDTH, LOGOHEIGHT } from "../../Constants";
import { useNavigate } from "react-router-dom";
import logo from "../../resources/logo.png";

export default function HeaderDesktop({ items }) {
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <div style={{ ...styles.container, display: smscreen ? "none" : "flex" }}>
      <a style={styles.logo} href="#" onClick={() => navigate("/")}>
        <img
          src={logo}
          width={LOGOWIDTH}
          height={LOGOHEIGHT}
          alt="rijschool nickan"
        />
      </a>
      <div style={styles.header}>
        {items.map((key) => (
          <HeaderItems key={key} item={key} style={styles.headerItem} />
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    flexDirection: "row",
    marginTop: "5%",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  headerItem: {
    fontSize: ".9em",
    marginRight: "3px",
    fontStyle: "italic",
    fontWeight: "bold",
    color: "white",
  },
  logo: {
    width: LOGOWIDTH,
    height: LOGOHEIGHT,
  },
};
