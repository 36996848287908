import React from "react";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

export default function LangButton(props) {
  const { style, ...otherProps } = props;
  const [_, i18n] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lng, setLng] = React.useState(i18n.language);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeLng = (value) => {
    i18n.changeLanguage(value);
    handleClose();
    setLng(value.toUpperCase());
  };

  React.useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Button
        variant="text"
        startIcon={<LanguageIcon />}
        style={{ color: "black", ...style }}
        onClick={handleClick}
        {...otherProps}
      >
        {lng}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => changeLng("nl")}>Nederlandse Site</MenuItem>
        <MenuItem onClick={() => changeLng("en")}>English Site</MenuItem>
        <MenuItem onClick={() => changeLng("fa")}>وب سایت فارسی</MenuItem>
      </Menu>
    </>
  );
}
