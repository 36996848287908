import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ContactButton(props) {
  const { style, ...otherProps } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => navigate("/contact")}
      variant="text"
      style={{ color: "black", ...style }}
      {...otherProps}
    >
      {t("contact")}
    </Button>
  );
}
