import React from "react";
import { Link, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

export default function EmailLink({ style }) {
  const email = "info@rijschoolnickan.nl";
  return (
    <Link href={`mailto:${email}`}>
      <Button style={style} variant="text" startIcon={<EmailIcon />}>
        {email}
      </Button>
    </Link>
  );
}
