import React from "react";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";

export default function WhatsappIcon() {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <Slide appear={true} direction="left" in={trigger}>
      <Fab
        href="https://wa.me/+31636000573"
        target={"_blank"}
        style={styles.whatsappIcon}
        color="primary"
        aria-label="add"
      >
        <WhatsAppIcon />
      </Fab>
    </Slide>
  );
}

const styles = {
  whatsappIcon: {
    position: "fixed",
    right: 15,
    bottom: 20,
    background: "#25D366",
  },
};
