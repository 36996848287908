import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import HideOnScroll from "./HideOnScroll";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../resources/logo2.jpeg";
import HeaderItems from "./HeaderItems";
import { PRIMARYCOLOR, LOGOWIDTH, LOGOHEIGHT } from "../../Constants";
import { useNavigate } from "react-router-dom";

export default function HeaderBar(props) {
  const { navItems } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 240;
  const { window } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <IconButton
        style={styles.closeMenue}
        onClick={handleDrawerToggle}
        sx={{ my: 2 }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <List>
        {navItems.map((key) => (
          <HeaderItems
            key={key}
            item={key}
            fullWidth
            style={styles.drawerItem}
          />
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <CssBaseline />
      <HideOnScroll>
        <AppBar component="nav" style={styles.appbar}>
          <Container maxWidth="md">
            <Toolbar style={styles.toolbar}>
              <a style={styles.logo} href="#" onClick={() => navigate("/")}>
                <img
                  src={logo}
                  width={LOGOWIDTH}
                  height={LOGOHEIGHT}
                  alt="rijschool nickan"
                />
              </a>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
                <Typography> &nbsp; Menu </Typography>
              </IconButton>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                {navItems.map((key) => (
                  <HeaderItems key={key} item={key} style={styles.headerItem} />
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

const styles = {
  appbar: {
    background: PRIMARYCOLOR,
  },
  toolbar: {
    display: "flex",
    padding: 0,
    justifyContent: "space-between",
  },
  logo: {
    width: LOGOWIDTH,
    height: LOGOHEIGHT,
  },
  closeMenue: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  drawerItem: {
    marginBottom: "15px",
  },
  headerItem: {
    color: "white",
  },
};
