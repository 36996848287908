import React from "react";
import { Link, Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function WhatsAppLink({ style }) {
  return (
    <Link href="https://wa.me/+31636000573" target={"_blank"}>
      <Button style={style} variant="text" startIcon={<WhatsAppIcon />}>
        06-36000573
      </Button>
    </Link>
  );
}
