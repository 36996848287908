import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SECONDARYCOLOR, PRIMARYCOLOR } from "../Constants";

export default function RequestLessonBannerSmall() {
  const xscreen = useMediaQuery("(max-width:400px)");
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <Paper style={styles.container} elevation={20}>
      <Typography
        variant="h6"
        style={{ ...styles.info, fontSize: xscreen ? ".9em" : "1.2em" }}
      >
        {t("rijbewijs laag prijs")}
      </Typography>
      <Button
        onClick={() => navigate("/proefles")}
        variant="contained"
        size="large"
        style={styles.button}
      >
        {t("proefles aanvragen")}!
      </Button>
    </Paper>
  );
}

const styles = {
  container: {
    position: "relative",
    top: "-10vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    background: PRIMARYCOLOR,
  },
  info: {
    color: "white",
    paddingBottom: "20px",
    whiteSpace: "nowrap",
  },
  button: {
    width: "250px",
    height: "50px",
    borderRadius: "25px",
    color: "white",
    background: SECONDARYCOLOR,
    fontWeight: "bold",
  },
};
