import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function NicanTitle({ location }) {
  const [t] = useTranslation();

  return (
    <div>
      <Typography style={styles.subtitle}>{`${t("autorijschool")} ${t(
        location
      )}`}</Typography>
      <Typography style={styles.title}>{t("nickan")}</Typography>
    </div>
  );
}

const styles = {
  subtitle: {
    fontSize: "1.5em",
  },
  title: {
    marginTop: "5px",
    marginBottom: "5vh",
    fontWeight: "bold",
    fontSize: "5em",
  },
};
