import React from "react";
import { Paper, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RedeemIcon from "@mui/icons-material/Redeem";
import { useTranslation } from "react-i18next";
import { SECONDARYCOLOR } from "../Constants";

export default function SellingPoints2() {
  const [t] = useTranslation();

  return (
    <div style={styles.container}>
      <Paper style={styles.cards} elevation={5}>
        <div style={styles.cardContainer}>
          <DirectionsCarIcon style={styles.icon} />
          <div style={styles.textContainer}>
            <Typography style={styles.title} variant="h6">
              {t("rijopleiding op maat")}
            </Typography>
            <Typography style={styles.description} variant="subtitle2">
              {t("rij op maat uitleg")}
            </Typography>
          </div>
        </div>
      </Paper>
      <Paper style={styles.cards} elevation={5}>
        <div style={styles.cardContainer}>
          <PersonIcon style={styles.icon} />
          <div style={styles.textContainer}>
            <Typography style={styles.title} variant="h6">
              {t("ervaaren rij-instructeur")}
            </Typography>
            <Typography style={styles.description} variant="subtitle2">
              {t("ervaaren uitleg")}
            </Typography>
          </div>
        </div>
      </Paper>
      <Paper style={styles.cards} elevation={5}>
        <div style={styles.cardContainer}>
          <CalendarMonthIcon style={styles.icon} />
          <div style={styles.textContainer}>
            <Typography style={styles.title} variant="h6">
              {t("start 16.5")}
            </Typography>
            <Typography style={styles.description} variant="subtitle2">
              {t("start 16.5 uitleg")}
            </Typography>
          </div>
        </div>
      </Paper>
      <Paper style={styles.cards} elevation={5}>
        <div style={styles.cardContainer}>
          <RedeemIcon style={styles.icon} />
          <div style={styles.textContainer}>
            <Typography style={styles.title} variant="h6">
              {t("gratis proefles")}!
            </Typography>
            <Typography style={styles.description} variant="subtitle2">
              {t("gratis proefles uitleg")}
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "100%",
    gap: "10px",
    marginBottom: "50px",
  },
  cards: {
    flexBasis: "40%",
    height: "100%",
    paddingBottom: "7vh",
    minWidth: "300px",
    flexGrow: 1,
    flex: 1,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    height: "220px",
  },
  textContainer: {
    flexBasis: "65%",
    display: "flex",
    flexDirection: "column",
    marginTop: "3vh",
  },
  icon: {
    flexBasis: "25%",
    fontSize: "4em",
    color: SECONDARYCOLOR,
    alignSelf: "center",
  },
  title: {
    marginBottom: "10px",
  },
  description: {
    textAlign: "justify",
    fontSize: ".9em",
  },
};
