import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function SellingPoint() {
  const [t] = useTranslation();

  return (
    <div>
      <div style={styles.feat}>
        <CheckIcon style={styles.checkIcon} />
        <Typography style={styles.featText} variant="h6">
          {t("hoog slagingspercentage")}
        </Typography>
      </div>
      <div style={styles.feat}>
        <CheckIcon style={styles.checkIcon} />
        <Typography style={styles.featText} variant="h6">
          {t("vaste rij-instructeur")}
        </Typography>
      </div>
      <div style={styles.feat}>
        <CheckIcon style={styles.checkIcon} />
        <Typography style={styles.featText} variant="h6">
          {t("geen wachttijd")}
        </Typography>
      </div>
      <div style={styles.feat}>
        <CheckIcon style={styles.checkIcon} />
        <Typography style={styles.featText} variant="h6">
          {t("betaalbaar en betrouwbaar")}
        </Typography>
      </div>
    </div>
  );
}

const styles = {
  feat: {
    color: "white",
    display: "flex",
    flexDirection: "row",
  },
  featText: {
    marginTop: "3vh",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  checkIcon: {
    marginTop: "3.3vh",
    marginRight: "10px",
    background: "green",
    color: "white",
  },
};
