import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Prices from "./pages/Prices";
import Contact from "./pages/Contact";
import Proefles from "./pages/Proefles";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/tarieven" element={<Prices />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/proefles" element={<Proefles />}></Route>
          <Route
            path="/rotterdam-noord"
            element={<Home location="Rotterdam Noord" />}
          ></Route>
          <Route path="/delft" element={<Home location="Delft" />}></Route>
          <Route path="/denhaag" element={<Home location="Den Haag" />}></Route>
          <Route
            path="/schiedam"
            element={<Home location="Schiedam" />}
          ></Route>
          <Route
            path="/vlaardingen"
            element={<Home location="Vlaardingen" />}
          ></Route>
          <Route
            path="/zoetermeer"
            element={<Home location="Zoetermeer" />}
          ></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
