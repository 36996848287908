import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function HideOnScroll(props) {
  const theme = useTheme();
  const { children } = props;

  const trigger = useScrollTrigger({ disableHysteresis: true });
  const smallscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Slide appear={true} direction="down" in={smallscreen ? true : trigger}>
      {children}
    </Slide>
  );
}
