import React from "react";
import { Paper, Typography } from "@mui/material";
import ContactForm from "./ContactForm";
import PhoneLink from "./ContactButtons/PhoneLink";
import EmailLink from "./ContactButtons/EmailLink";
import WhatsAppLink from "./ContactButtons/WhatsAppLink";
import { SECONDARYCOLOR } from "../Constants";
import { useTranslation } from "react-i18next";

export default function ProeflesBanner() {
  const [t] = useTranslation();

  return (
    <Paper elevation={10}>
      <div style={styles.container}>
        <div style={styles.contactInfo}>
          <Typography style={styles.title}>{t("start direct")}</Typography>
          <Typography style={styles.title}>{t("aanvragen via")}</Typography>
          <div style={styles.contactOptions}>
            <PhoneLink style={styles.link} />
            <WhatsAppLink style={styles.link} />
            <EmailLink style={styles.link} />
          </div>
        </div>
        {/*<ContactForm style={styles.contactForm} proefles={true} /> */}
      </div>
    </Paper>
  );
}

const styles = {
  container: {
    marginTop: "50px",
    marginBottom: "50px",
    paddingBottom: "40px",
    paddingTop: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "space-between",
    gap: "10px",
  },
  contactInfo: {
    display: "flex",
    width: "100%",
    minWidth: "300px",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
  },
  title: {
    color: "black",
    fontStyle: "bold",
    fontSize: "1.2em",
    marginTop: "40px",
    marginRight: "20px",
    marginLeft: "20px",
  },
  contactForm: {
    marginTop: "20px",
    width: "100%",
    minWidth: "300px",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
  },
  contactOptions: {
    marginTop: "40px",
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: SECONDARYCOLOR,
    marginTop: "5px",
    fontSize: "1em",
  },
};
