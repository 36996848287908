import React from "react";
import { Link, Button } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

export default function InstagramLink({ style }) {
  return (
    <Link
      href="https://instagram.com/rijschool_nickan?igshid=ZDdkNTZiNTM="
      target="_blank"
    >
      <Button style={style} variant="text" startIcon={<InstagramIcon />}>
        Instagram
      </Button>
    </Link>
  );
}
