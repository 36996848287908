import React from "react";
import { Link, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

export default function PhoneLink({ style }) {
  return (
    <Link href="tel:0031636000573">
      <Button style={style} variant="text" startIcon={<PhoneIcon />}>
        +31 6 36000573
      </Button>
    </Link>
  );
}
