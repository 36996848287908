import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: "/locals/{{lng}}/{{ns}}",
        allowMultiLoading: false,
        crossDomain: true,
      },
      lng: "nl",
      fallbackLng: "nl",
      debug: false,
      detection: {
        order: ["queryString", "cookie"],
        cache: ["cookie"],
      },
      interpolation: {
        escapeValue: false,
      },
    },
    (error, t) => {
      if (error) console.log(error);
    }
  );

export default i18n;
